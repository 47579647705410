import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-date-picker';

const DatePickerField = ({
  name,
  onChange,
}: {
  name: string;
  onChange?: (field: string, newVal: string) => void;
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, , helpers] = useField({ name });

  return (
    <DatePicker
      className='customDatePicker'
      {...field}
      format='MM/dd/yyyy'
      monthPlaceholder='mm'
      dayPlaceholder='dd'
      yearPlaceholder='yyyy'
      locale='en-GB'
      onChange={(val) => {
        if (Boolean(val) && val instanceof Date && !isNaN(val.getTime())) {
          const year = val.getFullYear();
          const month = val.getMonth() + 1;
          const day = val.getDate();
          if (year > 1000 && day > 0 && month > 0) {
            setFieldValue(field.name, val.toString());
            if (onChange) {
              onChange(field.name, val.toString());
            }
          }
        } else {
          setFieldValue(field.name, '');
          if (onChange) {
            onChange(field.name, '');
          }
        }
      }}
      onBlur={() => helpers.setTouched(true)}
    />
  );
};

export default DatePickerField;
