import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Section from '../../components/Section/Section';
import usePatients from '../../shared/hooks/usePatients';
import config from '../../config';
import Pagination from '../../components/Pagination/Pagination';
import StudiesCardsList from '../../components/StudyCardsList/StudiesCardsList';
import { UserInterface } from '../../shared/models/userType';
import NotFound from '../404/notFound';
import PatientDetailsMiniBox from '../../components/PatientsList/PatientDetailsMiniBox';
import BackButton from '../../components/Button/BackButton';
import { FilterInterface } from '../../shared/models/filter';
import { FieldTypes } from '../../shared/enum/fieldTypes';
import Filters from '../../components/Filters/Filters';
import { ReactComponent as SearchSVG } from '../../assets/Magnifer.svg';

const PatientPage = (): ReactElement => {
  const { id } = useParams();
  const [userData, setUserData] = useState<UserInterface>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<string, string>>({});

  const {
    fetchPatientStudies,
    studiesLoading,
    patientStudies,
    page,
    onPageChange,
    fetchPatientDetails,
  } = usePatients();

  useEffect(() => {
    const fetchUser = async () => {
      setNotFound(false);
      if (id) {
        const repsonseData = await fetchPatientDetails(id);
        if (repsonseData) setUserData(repsonseData);
        if (!repsonseData) setNotFound(true);
      }
    };
    fetchUser();
  }, [id, fetchPatientDetails, page]);

  useEffect(() => {
    if (id) {
      fetchPatientStudies({ patientId: id, page, filters: filterParams });
    }
  }, [id, fetchPatientStudies, page, filterParams]);

  const handleFilterFieldChange = useCallback(
    (field: string, newVal: string) => {
      setFilterParams((prevData) => {
        const newData = { ...prevData };
        if (newVal === undefined) {
          delete newData[field];
        } else {
          newData[field] = newVal;
        }
        return newData;
      });
      onPageChange({ selected: 0 });
    },
    [onPageChange],
  );

  const filters: FilterInterface[] = [
    {
      name: 'studyName',
      type: FieldTypes.TEXT,
      placeholder: 'Add complete name',
      label: 'Study name',
      icon: <SearchSVG width={24} height={24} />,
    },
    {
      name: 'studyDate',
      type: FieldTypes.DATE,
      label: 'Study date',
    },
  ];

  if (notFound) {
    return <NotFound />;
  }

  return (
    <>
      <Section flexSpacing>
        <BackButton />
        <Filters
          lateralContent={<PatientDetailsMiniBox patient={userData} />}
          setFiltersValue={handleFilterFieldChange}
          filters={filters}
        />
        {patientStudies && !studiesLoading && <StudiesCardsList studies={patientStudies.data} />}
        {studiesLoading && <StudiesCardsList loadingGhost />}
        {!studiesLoading && patientStudies?.data.length === 0 && 'No studies found'}
        {patientStudies?.meta &&
          patientStudies?.meta.totalItems / config.customization.studiesPerPage > 1 && (
            <Pagination
              meta={patientStudies.meta}
              page={page}
              onPageChange={onPageChange}
              perPage={config.customization.studiesPerPage}
            />
          )}
      </Section>
    </>
  );
};

export default PatientPage;
