import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../assets/arrowLeft.svg';
import styles from './BackButton.module.css';

type ButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  alternativeText?: string;
};

const BackButton = ({ disabled, onClick, alternativeText }: ButtonProps): ReactElement => {
  const navigate = useNavigate();
  const onButtonClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    navigate(-1);
  };

  return (
    <button className={styles.root} disabled={disabled} onClick={onButtonClick}>
      <div className={styles.icon}>
        <ArrowLeft width={24} height='100%' />
      </div>
      <span>{alternativeText ?? 'Back'}</span>
    </button>
  );
};

export default BackButton;
