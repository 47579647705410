import React, { ReactElement } from 'react';
import cx from 'classnames';
import styles from './PatientListItem.module.css';
import { UserInterface } from '../../shared/models/userType';
import calculateAge from '../../shared/utils/calculateAge';
import formatDate from '../../shared/utils/formatDate';
import Button from '../Button/Button';
import Tag from '../Tag/Tag';
import config from '../../config';
import { Link } from 'react-router-dom';

const PatientListItem = ({
  patient,
  loadingGhost,
}: {
  patient?: UserInterface;
  loadingGhost?: boolean;
}): ReactElement => {
  const createdAtDate = patient?.createdAt ? new Date(patient?.createdAt) : null;
  const currentDate = new Date();
  let diffDays = Number.MAX_SAFE_INTEGER;
  if (createdAtDate) {
    const diffTime = Math.abs(currentDate.getTime() - createdAtDate.getTime());
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  const patientUrl = config.routes.patientRecords.replace(':id', String(patient?.id));
  return (
    <div className={cx(styles.root, loadingGhost && styles.loadingGhost)}>
      <div className={styles.section}>
        {loadingGhost && (
          <>
            <div className={styles.cell} />
            <div className={styles.cell} />
            <div className={styles.cell} />
          </>
        )}
        {!loadingGhost && (
          <>
            <div className={styles.cell}>
              <Link to={patientUrl} className={styles.name}>
                {patient.data.name}
              </Link>
              {diffDays <= 300 && (
                <div className={styles.newTag}>
                  <Tag type='new' small />
                </div>
              )}
            </div>
            {patient.data.birthday && (
              <>
                <div className={styles.cell}>
                  <span className={styles.label}>Age:</span> {calculateAge(patient.data.birthday)}
                </div>

                <div className={styles.cell}>
                  <span className={styles.label}>DOB:</span> {formatDate(patient.data.birthday)}
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className={styles.section}>
        <Button style='naked' toPage={patientUrl} loadingGhost={loadingGhost}>
          View studies
        </Button>
      </div>
    </div>
  );
};

export default PatientListItem;
