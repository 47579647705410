import { useCallback, useState } from 'react';
import { StudyRequestFormInterface } from '../models/dicom';
import { requestAccess } from '../services/dicom.service';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { RequestAccessErrors } from '../enum/requestAccessErrors';
import filterEmptyStringsFromObject from '../utils/filterEmptyStringsFromObject';
import { formatDateInheritLocale } from '../utils/formatDateInheritLocale';

interface UseRequestHook {
  formLoading: boolean;
  patientValidationdata: StudyRequestFormInterface;
  setPatientValidationData: React.Dispatch<React.SetStateAction<StudyRequestFormInterface>>;
  showSecondStep: boolean;
  setShowSecondStep: React.Dispatch<React.SetStateAction<boolean>>;
  onCancelRelease: () => void;
  handleRequestForm: (formValues: StudyRequestFormInterface) => Promise<string>;
}

const useRequest = (): UseRequestHook => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [showSecondStep, setShowSecondStep] = useState<boolean>(false);
  const [patientValidationdata, setPatientValidationData] = useState<StudyRequestFormInterface>();

  const onCancelRelease = () => {
    setShowSecondStep(false);
    setFormLoading(false);
  };

  const handleRequestForm = useCallback(
    async (formValues: StudyRequestFormInterface): Promise<string> => {
      try {
        setFormLoading(true);
        const filteredValues = {
          patient: {
            ...formValues.patient,
            birthday: formatDateInheritLocale(formValues.patient.birthday),
          },
          provider: filterEmptyStringsFromObject(formValues.provider),
        };

        const request = await requestAccess(filteredValues as StudyRequestFormInterface);
        return request.url;
      } catch (error) {
        console.log(error);
        if (error instanceof AxiosError && error.response) {
          if (error.response.data.message === RequestAccessErrors.INVALID_BIRTHDAY) {
            toast.error('Birthday does not match with user data.');
            return;
          }
        }
        toast.error('An error occurred during request.');
        return;
      } finally {
        setFormLoading(false);
      }
    },
    [],
  );

  return {
    formLoading,
    patientValidationdata,
    setPatientValidationData,
    showSecondStep,
    setShowSecondStep,
    onCancelRelease,
    handleRequestForm,
  };
};

export default useRequest;
