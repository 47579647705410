import { AxiosProgressEvent } from 'axios';
import { axiosInstance } from '../../api/AxiosInstance';
import {
  AllStudiesRequestAccessResponseInterface,
  Dicom,
  DicomAddFormInterface,
  DicomPatch,
  StudyRequestFormInterface,
  StudyRequestResponseInterface,
} from '../models/dicom';
import config from '../../config';
import { StudyInterface } from '../models/study';
import { StudyFileInterface } from '../models/file';
import { PaginatedResponse } from '../models/paginatedResponse';
import { getPaginateParams } from '../utils/getPaginateParams';
import { formatDateInheritLocale } from '../utils/formatDateInheritLocale';
import { StudyShareInterface } from '../models/shares';
// import { ShareStatus } from '../enum/shareStatus';

export const releaseStudy = async ({
  values,
  onUploadProgress,
  files,
  externalToken,
  prevalidateRequest,
}: {
  values: DicomAddFormInterface;
  onUploadProgress?: React.Dispatch<React.SetStateAction<number>>;
  files?: File[];
  externalToken?: string;
  prevalidateRequest?: boolean;
}): Promise<Dicom> => {
  const formData = new FormData();

  if (files) {
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
  }

  if (!externalToken) {
    for (const key in values.user) {
      if (values.user[key]) {
        let valueToAppend: string = values.user[key];
        if (key === 'birthday') {
          valueToAppend = formatDateInheritLocale(valueToAppend);
        }
        formData.append(`user[${key}]`, valueToAppend);
      }
    }
  }

  for (const key in values.study) {
    if (values.study[key]) {
      let valueToAppend: string = values.study[key];
      if (key === 'date') {
        valueToAppend = formatDateInheritLocale(valueToAppend);
      }
      formData.append(`study[${key}]`, valueToAppend);
    }
  }

  for (const key in values.extraData) {
    formData.append(`extraData[${key}]`, values.extraData[key]);
  }

  const postConfig = {
    onUploadProgress: onUploadProgress
      ? (progressEvent: AxiosProgressEvent) => {
          const total = progressEvent.total || 1;
          const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
          onUploadProgress(percentCompleted);
        }
      : null,
    headers: {
      ...(prevalidateRequest
        ? { 'Content-Type': 'application/json' }
        : { 'Content-Type': 'multipart/form-data' }),
      ...(externalToken ? { Authorization: `Bearer ${externalToken}` } : {}),
    },
    timeout: config.customization.axiosStudyUploadTimeout,
  };
  const postUrl = prevalidateRequest
    ? config.api.studyPrevalidate
    : externalToken
      ? config.api.accessStudy
      : config.api.study;

  const response = await axiosInstance.post(postUrl, formData, postConfig);

  return response.data;
};

export const requestAccess = async (
  values: StudyRequestFormInterface,
): Promise<StudyRequestResponseInterface> => {
  const response = await axiosInstance.post(config.api.accessRequest, values);
  return response.data;
};

export const updateDicom = async (values: DicomPatch, id: number): Promise<Dicom> => {
  const response = await axiosInstance.patch(config.api.studyId.replace(':id', String(id)), {
    data: values,
  });
  return response.data;
};

export const getUserStudies = async (
  page: number,
  filters?: Record<string, string>,
): Promise<PaginatedResponse<StudyInterface>> => {
  const pageParams = getPaginateParams(page, config.customization.studiesPerPage);
  const response = await axiosInstance.get(config.api.study, {
    params: {
      ...pageParams,
      ...filters,
    },
  });
  return response.data;
};

export const getPatientStudies = async (
  patientId: string,
  page: number,
  filters?: Record<string, string>,
): Promise<PaginatedResponse<StudyInterface>> => {
  const pageParams = getPaginateParams(page, config.customization.studiesPerPage);
  const response = await axiosInstance.get(
    config.api.providerPatientStudies.replace(':id', patientId),
    {
      params: {
        ...pageParams,
        ...filters,
      },
    },
  );
  return response.data;
};

export const getSharedWithMeStudies = async (): Promise<PaginatedResponse<StudyInterface>> => {
  const response = await axiosInstance.get(config.api.studySharedWithMe);
  return response.data;
};

export const getStudyByid = async (id: string): Promise<StudyInterface> => {
  const response = await axiosInstance.get(config.api.studyId.replace(':id', id));
  return response.data;
};

export const getAllStudies = async (
  page: number,
  filters?: Record<string, string>,
): Promise<PaginatedResponse<StudyInterface>> => {
  const pageParams = getPaginateParams(page, config.customization.historyStudiesPerPage);
  const response = await axiosInstance.get(config.api.studiesAll, {
    params: {
      ...pageParams,
      ...filters,
    },
  });
  return response.data;
};

export const getAllStudiesRequests = async (
  page: number,
  filters?: Record<string, string>,
): Promise<PaginatedResponse<AllStudiesRequestAccessResponseInterface>> => {
  const pageParams = getPaginateParams(page, config.customization.historyStudiesPerPage);
  const response = await axiosInstance.get(config.api.accessStudyAll, {
    params: {
      ...pageParams,
      ...filters,
    },
  });
  return response.data;
};

export const getStudyFilesByid = async (
  id: string,
  page: number,
): Promise<PaginatedResponse<StudyFileInterface>> => {
  const params = getPaginateParams(page, config.customization.filesPerPage);
  const response = await axiosInstance.get(config.api.studyIdFiles.replace(':id', id), {
    params,
  });
  return response.data;
};

export const getStudySharesByid = async (
  id: string,
  page: number,
): Promise<PaginatedResponse<StudyShareInterface>> => {
  const pageParams = getPaginateParams(page, config.customization.sharesPerPage);
  const response = await axiosInstance.get(config.api.studyShares.replace(':id', id), {
    params: {
      ...pageParams,
      // status: `${ShareStatus.ACTIVE},${ShareStatus.SHARE_PENDING},${ShareStatus.REVOKE_PENDING}`,
    },
  });
  return response.data;
};

export const postDicomToIpfs = async (id: string): Promise<Dicom> => {
  const response = await axiosInstance.post(config.api.dicomIdIpfs.replace(':id', id));
  return response.data;
};

export const mintNft = async (id: string): Promise<Dicom> => {
  const response = await axiosInstance.post(config.api.dicomIdMint.replace(':id', id));
  return response.data;
};

export const shareStudy = async (
  id: string,
  email: string,
  description: string,
): Promise<Dicom> => {
  const response = await axiosInstance.post(config.api.studyIdShare.replace(':id', id), {
    email,
    description,
  });
  return response.data;
};

export const revokesShareStudy = async (id: string, email: string): Promise<Dicom> => {
  const response = await axiosInstance.post(config.api.studyIdRevoke.replace(':id', id), {
    email,
    description: '',
  });
  return response.data;
};

export const downloadDicomFileById = async (id: string) => {
  const response = await axiosInstance.get(config.api.dicomIdDownload.replace(':id', id), {
    responseType: 'blob',
  });
  return response.data;
};
