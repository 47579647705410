import React, { ReactElement, ReactNode, useState } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import styles from './Button.module.css';
import Modal from '../Modal/Modal';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import { ModalInterface } from '../../shared/models/modal';

type ButtonProps = {
  children: ReactNode;
  style?: 'navy' | 'primary' | 'naked' | 'blank';
  fullWidth?: boolean;
  size?: 'large' | 'small';
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  icon?: ReactElement;
  onClick?: () => void;
  loading?: boolean;
  toPage?: string;
  modal?: ModalInterface;
  loadingGhost?: boolean;
  percentageLoaded?: number;
};

const Button = ({
  children,
  style,
  fullWidth,
  size,
  disabled,
  type,
  icon,
  onClick,
  loading,
  toPage,
  modal,
  loadingGhost,
  percentageLoaded,
}: ButtonProps): ReactElement => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  const goToPage = () => {
    if (toPage) navigate(toPage);
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <button
        className={cx(
          styles.root,
          fullWidth && styles.fullWidth,
          style === 'naked' && styles.naked,
          style === 'navy' && styles.navy,
          style === 'blank' && styles.blank,
          size === 'large' && styles.large,
          icon && styles.withIcon,
          loading && styles.loading,
          loadingGhost && styles.loadingGhost,
        )}
        disabled={disabled ?? loading}
        type={type}
        onClick={type !== 'submit' && modal ? toggleModal : toPage ? goToPage : onClick}
      >
        {!loading && icon}
        {loading && (
          <div className={styles.loadingIconWrapper}>
            <div className={styles.loadingIconInner}>
              <LoadingCircle percentageLoaded={percentageLoaded} size={17} />
            </div>
          </div>
        )}
        <span>{children}</span>
      </button>
      {showModal && modal && <Modal toggleView={toggleModal} modal={modal} />}
    </>
  );
};

export default Button;
