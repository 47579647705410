import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { ReactComponent as SearchSVG } from '../../assets/Magnifer.svg';
import { StudyStatus } from '../../shared/enum/study';
import useStudy from '../../shared/hooks/useStudy';
import { FilterInterface } from '../../shared/models/filter';
import { FieldTypes } from '../../shared/enum/fieldTypes';
import Section from '../Section/Section';
import Filters from '../Filters/Filters';
import StudiesCardsList from '../StudyCardsList/StudiesCardsList';
import config from '../../config';
import Pagination from '../Pagination/Pagination';
import RecentActivities from '../RecentActivities/RecentActivities';

const MyMedicalRecords = (): ReactElement => {
  const [filterParams, setFilterParams] = useState<Record<string, string>>({
    status: String(StudyStatus.MINT_COMPLETED),
  });
  const { userStudies, fetchUserStudies, fetchLoading, page, onPageChange } = useStudy();

  useEffect(() => {
    fetchUserStudies({ page, filters: filterParams });
  }, [fetchUserStudies, page, filterParams]);

  const handleFilterFieldChange = useCallback(
    (field: string, newVal?: string) => {
      setFilterParams((prevData) => {
        const newData = { ...prevData };
        if (!newVal) {
          delete newData[field];
        } else {
          newData[field] = newVal;
        }
        return newData;
      });
      onPageChange({ selected: 0 });
    },
    [onPageChange],
  );

  const filters: FilterInterface[] = [
    {
      name: 'studyName',
      type: FieldTypes.TEXT,
      placeholder: 'Add complete name',
      label: 'Study name',
      icon: <SearchSVG width={24} height={24} />,
    },
    {
      name: 'studyDate',
      type: FieldTypes.DATE,
      label: 'Study date',
    },
  ];

  return (
    <>
      <Section flexSpacing>
        <Filters setFiltersValue={handleFilterFieldChange} filters={filters} />
        {userStudies && !fetchLoading && (
          <StudiesCardsList patientView studies={userStudies.data} />
        )}
        {fetchLoading && <StudiesCardsList loadingGhost patientView />}
        {!fetchLoading && userStudies?.data?.length === 0 && 'No studies found'}
        {userStudies?.meta &&
          userStudies?.meta.totalItems / config.customization.studiesPerPage > 1 && (
            <Pagination
              meta={userStudies.meta}
              page={page}
              onPageChange={onPageChange}
              perPage={config.customization.studiesPerPage}
            />
          )}
      </Section>
      <Section title='Recent activities'>
        <RecentActivities />
      </Section>
    </>
  );
};

export default MyMedicalRecords;
