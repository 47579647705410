import React, { ReactElement, useCallback, useState } from 'react';
import cx from 'classnames';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';

import styles from './requestRecord.module.css';

import SectionDouble from '../../components/SectionDouble/SectionDouble';
import InputField from '../../components/Form/InputField';
import Button from '../../components/Button/Button';
import { StudyRequestFormInterface } from '../../shared/models/dicom';
import messages from '../../shared/staticText/messages';
import useRequest from '../../shared/hooks/useRequest';
import Section from '../../components/Section/Section';
import { ReactComponent as RequestProcessingSvg } from '../../assets/request_process.svg';
import config from '../../config';

const RequestRecordPage = (): ReactElement => {
  const [formValues] = useState<StudyRequestFormInterface>({
    patient: {
      name: '',
      email: '',
      ssn: '',
      birthday: '',
    },
    provider: {
      name: '',
      email: '',
      npi: '',
      phone: '',
      address1: '',
      address2: '',
      country: '',
      city: '',
      zipcode: '',
    },
  });
  const [recordAccessUrl, setRecordAccessUrl] = useState<string>();

  const {
    setPatientValidationData,
    showSecondStep,
    setShowSecondStep,
    onCancelRelease,
    handleRequestForm,
    formLoading,
  } = useRequest();

  const getAccesPdfUrl = useCallback((urlHash: string) => {
    const hashFromUrl = urlHash.substring(urlHash.indexOf('=') + 1);
    const backendAccessUrl = `${config.routes.accessPdf}?hash=${hashFromUrl}`;
    return backendAccessUrl;
  }, []);

  const onSubmit = async (
    values: StudyRequestFormInterface,
    { resetForm }: FormikHelpers<StudyRequestFormInterface>,
  ) => {
    const requestAccessHash = await handleRequestForm(values);
    if (!requestAccessHash) return;
    setShowSecondStep(true);
    setRecordAccessUrl(requestAccessHash);
    resetForm();
  };

  const validationSchema = Yup.object({
    patient: Yup.object().shape({
      name: Yup.string().required(messages.requiredField),
      email: Yup.string().email(messages.invalidEmail).required(messages.requiredField),
      ssn: Yup.string()
        .required(messages.requiredField)
        .min(5, messages.min5charsLength)
        .max(5, messages.min5charsLength),
      birthday: Yup.date().required(messages.requiredField).max(new Date(), messages.onlyPastDates),
    }),
    provider: Yup.object().shape({
      name: Yup.string().required(messages.requiredField),
      email: Yup.string().email(messages.invalidEmail),
      npi: Yup.string().required(messages.requiredField),
      phone: Yup.string().required(messages.requiredField),
    }),
  });

  if (showSecondStep) {
    return (
      <Section>
        <div className={styles.succesWrapper}>
          <RequestProcessingSvg width={396} height={82} />
          <h1>Request sent</h1>
          <p>
            The request has been sent. An email with the details for this request with the pdf and
            access code have been sent to your email address, and external providers email address.
          </p>
          <Button toPage={getAccesPdfUrl(recordAccessUrl)} style='navy' size='large'>
            Download PDF
          </Button>
          <Button onClick={onCancelRelease} style='naked' size='large'>
            New request
          </Button>
        </div>
      </Section>
    );
  }

  return (
    <Formik initialValues={formValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ resetForm, errors, values }) => {
        const isFirstStepValid =
          values?.patient?.name &&
          !errors?.patient?.name &&
          !errors?.patient?.email &&
          !errors?.patient?.ssn &&
          !errors?.patient?.birthday &&
          !errors?.provider?.name &&
          !errors?.provider?.email &&
          !errors?.provider?.npi &&
          !errors?.provider?.phone;
        const disableContinueBtn = !isFirstStepValid || formLoading;

        setPatientValidationData(values);

        const onCancel = () => {
          resetForm();
          onCancelRelease();
        };

        return (
          <SectionDouble
            leftSide={
              <div>
                <div className={styles.title}>
                  From whom would you like to request medical records?
                </div>
                <Form className={styles.form}>
                  <div className={cx(styles.firstStep, styles.fieldsGroup)}>
                    <InputField name='provider.name' label='Name' type='text' required />
                    <InputField name='provider.email' label='Email' type='text' />
                    <InputField
                      name='provider.npi'
                      label='NPI'
                      type='text'
                      required
                      regex={/^\d+$/}
                    />
                    <InputField name='provider.phone' label='Phone' type='phone' required />
                  </div>
                  <div className={styles.subtitle}>Address</div>
                  <div className={cx(styles.firstStep, styles.fieldsGroup)}>
                    <InputField name='provider.address1' label='Address 1' type='text' />
                    <InputField name='provider.address2' label='Address 2' type='text' />
                    <InputField name='provider.country' label='Country' type='text' />
                    <InputField name='provider.city' label='City/Providence' type='text' />
                    <InputField name='provider.zipcode' label='Zip / postal code' type='text' />
                  </div>
                </Form>
              </div>
            }
            rightSide={
              <div>
                <div className={cx(styles.title, styles.minHeight)}>Patient details</div>
                <Form className={styles.form}>
                  <div className={cx(styles.firstStep, styles.fieldsGroup)}>
                    <InputField name='patient.name' label='Name' type='text' required />
                    <InputField name='patient.email' label='Email' type='text' required />
                    <InputField
                      name='patient.ssn'
                      label='SSN'
                      type='password'
                      required
                      maxLength={5}
                      regex={/^\d*$/}
                    />
                    <InputField
                      name='patient.birthday'
                      type='date'
                      label='Date of birth'
                      required
                    />
                    <div className={styles.buttons}>
                      <Button
                        type='button'
                        style='naked'
                        size='large'
                        fullWidth
                        onClick={onCancel}
                        disabled={formLoading}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        style='navy'
                        size='large'
                        fullWidth
                        disabled={disableContinueBtn}
                        loading={formLoading}
                      >
                        Generate access
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            }
          />
        );
      }}
    </Formik>
  );
};

export default RequestRecordPage;
