import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import config from '../config';
import NotFound from './404/notFound';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import SharedWithMePage from './sharedWithMe';
import ReleaseRecordPage from './releaseRecord/releaseRecord';
import { UserRoles } from '../shared/enum/userRoles';
import AuthPageWrapper from './login/AuthPageWrapper';
import SignIn from './login/SignIn';
import StudyPage from './study/[id]';
import RequestRecordPage from './requestRecord/requestRecord';
import ExternalRelease from './external/external';
import ReleaseHistoryPage from './releaseHistory/releaseHistory';
import PatientsPage from './patients/patients';
import PatientPage from './patients/[id]';
import AccessPDF from './accessPdf/accessPdf';
import RequestHistoryPage from './requestHistory/requestHistory';
import RequestInfoPage from './releaseHistory/[id]';
import HomePage from '.';

const Pages = (): ReactElement => {
  return (
    <Routes>
      <Route
        path={config.routes.signIn}
        element={
          <AuthPageWrapper>
            <SignIn />
          </AuthPageWrapper>
        }
      />
      <Route path={config.routes.external} element={<ExternalRelease />} />
      <Route
        path={config.routes.home}
        element={
          <PageWrapper>
            <HomePage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.shared}
        element={
          <PageWrapper>
            <SharedWithMePage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.release}
        element={
          <PageWrapper noRows roleAllowed={UserRoles.PROVIDER}>
            <ReleaseRecordPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.request}
        element={
          <PageWrapper noRows roleAllowed={UserRoles.PROVIDER}>
            <RequestRecordPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.patients}
        element={
          <PageWrapper roleAllowed={UserRoles.PROVIDER}>
            <PatientsPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.patientRecords}
        element={
          <PageWrapper roleAllowed={UserRoles.PROVIDER}>
            <PatientPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.releaseHistory}
        element={
          <PageWrapper roleAllowed={UserRoles.PROVIDER}>
            <ReleaseHistoryPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.requestHistory}
        element={
          <PageWrapper roleAllowed={UserRoles.PROVIDER}>
            <RequestHistoryPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.requestHistoryFromId}
        element={
          <PageWrapper roleAllowed={UserRoles.PROVIDER}>
            <RequestInfoPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.studyId}
        element={
          <PageWrapper>
            <StudyPage />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.accessPdf}
        element={
          <PageWrapper noValidation>
            <AccessPDF />
          </PageWrapper>
        }
      />
      <Route
        path={config.routes.recoverPassword}
        element={
          <PageWrapper noValidation>
            <p>Recover Password!</p>
          </PageWrapper>
        }
      />
      <Route
        path='*'
        element={
          <PageWrapper>
            <NotFound />
          </PageWrapper>
        }
      />
    </Routes>
  );
};

export default Pages;
