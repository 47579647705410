import React, { ReactElement, useEffect, useState } from 'react';
import cx from 'classnames';
import { InfoSquare } from 'react-bootstrap-icons';
import styles from './StudyShares.module.css';
import useStudy from '../../shared/hooks/useStudy';
import { StudyInterface } from '../../shared/models/study';
import Pagination from '../Pagination/Pagination';
import config from '../../config';
import ShareStudyButton from '../ShareStudyButton/ShareStudyButton';
import timeIcon from '../../assets/time.svg';
import Button from '../Button/Button';
import { useUserContext } from '../../shared/context/user.context';
import { ShareStatus } from '../../shared/enum/shareStatus';

const StudyShares = ({
  study,
  loadingGhost,
}: {
  study: StudyInterface;
  loadingGhost?: boolean;
}): ReactElement => {
  const { fetchStudyShares, page, studyShares, onPageChange, fetchLoading } = useStudy();
  const [, setRefreshTimer] = useState(config.customization.autoRefreshTimerInSeconds);
  const [allowSharedView, setAllowSharedView] = useState(false);
  const { user } = useUserContext();

  useEffect(() => {
    if (study?.id && allowSharedView) {
      fetchStudyShares(study?.id, page);
    }
  }, [study?.id, fetchStudyShares, page, allowSharedView]);
  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshTimer((prevTimer) => {
        if (prevTimer === 1 && allowSharedView) {
          fetchStudyShares(study?.id, page, true);
          return config.customization.autoRefreshTimerInSeconds;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [study?.id, fetchStudyShares, page, allowSharedView]);

  useEffect(() => {
    if (study?.userId?.toString() == user.id) {
      setAllowSharedView(true);
    } else {
      setAllowSharedView(false);
    }
  }, [user, study]);

  if (!allowSharedView) {
    return;
  }

  return (
    <div className={cx(styles.root)}>
      <div className={styles.shareWrapper}>
        <div className={styles.fullWidthCard}>
          <h3 className={styles.cardTitle}>Shared with</h3>
          <div className={styles.sharedWithContainer}>
            {fetchLoading &&
              [...Array(config.customization.sharesPerPage)].map((_, index) => (
                <div key={index} className={cx(styles.sharedWithRowContainer)}>
                  <div className={styles.sharedWithRow}>
                    <div
                      className={cx(
                        styles.sharedWithColumn,
                        styles.descriptionText,
                        styles.loadingGhost,
                      )}
                    >
                      lorem ipsum
                    </div>
                    <div className={styles.bullet} />
                    <div
                      className={cx(styles.sharedWithColumn, styles.smallText, styles.loadingGhost)}
                    >
                      lorem ipsum
                    </div>
                    <div className={styles.bullet} />
                    <div
                      className={cx(styles.sharedWithColumn, styles.smallText, styles.loadingGhost)}
                    >
                      <img src={timeIcon} alt='Time icon' className={styles.icon} />
                      1234567890
                    </div>
                  </div>
                  <div className={cx(styles.sharedWithColumnButton, styles.smallText)}>
                    <Button loadingGhost>Loading</Button>
                  </div>
                </div>
              ))}
            {!fetchLoading &&
              studyShares?.data
                ?.filter((share) => share.status !== ShareStatus.REVOKED)
                .map((share) => (
                  <div key={share.id} className={styles.sharedWithRowContainer}>
                    <div className={styles.sharedWithRow}>
                      <div className={cx(styles.sharedWithColumn, styles.descriptionText)}>
                        {share.user.data.name}
                        {share.description && (
                          <div className={styles.descriptionInner}>
                            <InfoSquare /> {share.description}
                          </div>
                        )}
                      </div>
                      <div className={styles.bullet} />
                      <div className={cx(styles.sharedWithColumn, styles.smallText)}>
                        {share.user.data.email}
                      </div>
                      <div className={styles.bullet} />
                      <div className={cx(styles.sharedWithColumn, styles.smallText)}>
                        <img src={timeIcon} alt='Time icon' className={styles.icon} />
                        {new Date(share.updatedAt).toLocaleDateString()}
                      </div>
                    </div>
                    <div className={cx(styles.sharedWithColumnButton, styles.smallText)}>
                      <ShareStudyButton
                        email={share.user.data.email}
                        share={share}
                        study={study}
                        fetchStudyShares={fetchStudyShares}
                        page={page}
                        isRevoke
                        loadingGhost={loadingGhost}
                      />
                    </div>
                  </div>
                ))}
          </div>
          {studyShares?.meta &&
            studyShares?.meta.totalItems / config.customization.sharesPerPage > 1 && (
              <Pagination
                meta={studyShares.meta}
                page={page}
                onPageChange={onPageChange}
                perPage={config.customization.sharesPerPage}
              />
            )}
          <div>
            <ShareStudyButton
              study={study}
              fetchStudyShares={fetchStudyShares}
              page={page}
              loadingGhost={loadingGhost}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyShares;
