import React, { ReactElement, useMemo } from 'react';
import cx from 'classnames';
import styles from './Menu.module.css';
import config from '../../config';
import { NavLink } from 'react-router-dom';
import { useUserContext } from '../../shared/context/user.context';
import { UserRoles } from '../../shared/enum/userRoles';

type MenuItemType = {
  label: string;
  route: string;
  associatedRoutes?: string[];
};

const Menu = (): ReactElement => {
  const { user } = useUserContext();

  const patientMenuItems = useMemo(
    (): MenuItemType[] => [
      {
        label: 'My medical records',
        route: config.routes.home,
      },
      {
        label: 'Shared with me',
        route: config.routes.shared,
      },
      {
        label: 'Authorizations',
        route: '/authorizations',
      },
    ],
    [],
  );

  const doctorMenuItems = useMemo(
    (): MenuItemType[] => [
      {
        label: 'Release record',
        route: config.routes.release,
      },
      {
        label: 'Request record',
        route: '/request-record',
      },
      {
        label: 'Patients record',
        route: config.routes.patients,
      },
      {
        label: 'History',
        route: config.routes.releaseHistory,
        associatedRoutes: [config.routes.requestHistory],
      },
    ],
    [],
  );

  const menuItems = useMemo(() => {
    if (user?.roleId === UserRoles.PATIENT) return patientMenuItems;
    if (user?.roleId === UserRoles.PROVIDER) return doctorMenuItems;
    return [];
  }, [doctorMenuItems, patientMenuItems, user?.roleId]);

  const getIsActive = (item: MenuItemType) => {
    return (
      location.pathname === item.route ||
      item.associatedRoutes?.includes(location.pathname) ||
      false
    );
  };

  return (
    <div className={styles.root}>
      {menuItems.map((item) => (
        <NavLink
          key={item.route}
          to={item.route}
          className={({ isActive }) =>
            cx({ [styles.active]: isActive || getIsActive(item) }, styles.link)
          }
        >
          {item.label}
        </NavLink>
      ))}
    </div>
  );
};

export default Menu;
