import React, { ReactElement } from 'react';
import cx from 'classnames';
import { ReactComponent as BrandMark } from '../../assets/brandMark.svg';
import styles from './StudyCard.module.css';
import Button from '../Button/Button';
import config from '../../config';
import { StudyInterface } from '../../shared/models/study';
import formatDate from '../../shared/utils/formatDate';
import Tag from '../Tag/Tag';
import { formatGender } from '../../shared/utils/formatGender';
import calculateAge from '../../shared/utils/calculateAge';
import ShareStudyButton from '../ShareStudyButton/ShareStudyButton';

const StudyCard = ({
  study,
  patientView,
}: {
  study: StudyInterface;
  key: string | number;
  patientView?: boolean;
}): ReactElement => {
  const infoRows: { label: string; value: string }[] = [
    ...(study?.data?.studyDetails?.date
      ? [{ label: 'Taken on', value: formatDate(study.data.studyDetails.date) }]
      : []),
    ...(study?.data?.extraData?.providerName
      ? [{ label: 'Taken in', value: study.data?.extraData?.providerName }]
      : []),
    ...(study?.data?.studyDetails?.physicianName
      ? [{ label: 'Taken by', value: study.data?.studyDetails?.physicianName }]
      : []),
  ];

  const patientInfoRows: { label: string; value: string }[] = [
    ...(study?.user?.data?.name ? [{ label: 'Patient', value: study?.user?.data?.name }] : []),
    ...(study?.data?.extraData['x00100040']
      ? [{ label: 'Gender', value: formatGender(study.data?.extraData['x00100040']) }]
      : []),
    ...(study?.user?.data?.birthday
      ? [{ label: 'Age', value: `${calculateAge(study?.user?.data?.birthday)}` }]
      : []),
    ...(study?.user?.data?.birthday
      ? [{ label: 'DOB', value: formatDate(study?.user?.data?.birthday) }]
      : []),
  ];

  const createdAtDate = study.createdAt ? new Date(study.createdAt) : null;
  const currentDate = new Date();
  let diffDays = Number.MAX_SAFE_INTEGER;
  if (createdAtDate) {
    const diffTime = Math.abs(currentDate.getTime() - createdAtDate.getTime());
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  return (
    <div className={styles.root}>
      <div className={styles.nameTagWrapper}>
        <div className={styles.name}>
          <div className={styles.image}>
            <BrandMark width={32} height={'100%'} />
          </div>
          <h2>{study.data?.studyDetails?.name}</h2>
        </div>
        {diffDays <= 5 && (
          <div className={styles.newTag}>
            <Tag type='new' />
          </div>
        )}
      </div>
      {!patientView && patientInfoRows.length > 0 && (
        <div className={styles.infoRows}>
          {patientInfoRows.map((item) => (
            <div className={styles.infoRow} key={item.label}>
              <div className={styles.label}>{item.label}:</div>
              <div className={styles.value}>{item.value}</div>
            </div>
          ))}
        </div>
      )}
      <div className={styles.infoRows}>
        {infoRows.map((item) => (
          <div className={styles.infoRow} key={item.label}>
            <div className={styles.label}>{item.label}:</div>
            <div className={styles.value}>{item.value}</div>
          </div>
        ))}
      </div>
      <hr className={cx(styles.separator, styles.absolute)} />
      <div className={cx(styles.buttons, styles.absolute, patientView && styles.buttonsOnCols)}>
        {/* {study.locked && (
          <Button fullWidth disabled style='naked'>
            Locked
          </Button>
        )}
        {!study.locked && (
          <> */}
        <Button toPage={config.routes.studyId.replace(':id', String(study.id))}>View</Button>
        {patientView && <ShareStudyButton study={study} alternativeShareButtonText={'Share'} />}
        {/* </>
       )} */}
      </div>
    </div>
  );
};

export default StudyCard;
