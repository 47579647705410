import React, { ReactElement, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import SectionDouble from '../../components/SectionDouble/SectionDouble';
import styles from './releaseRecord.module.css';
import InputField from '../../components/Form/InputField';
import Button from '../../components/Button/Button';
import FileDrop from '../../components/AddStudy/FileDrop';
import { DicomAddFormInterface } from '../../shared/models/dicom';
import useRelease from '../../shared/hooks/useRelease';
import dicomLabels from '../../shared/staticText/dicomLabels';
import messages from '../../shared/staticText/messages';
import ReleaseInstructions from '../../components/RealeaseForm/Instructions';
import BackButton from '../../components/Button/BackButton';
import InlineInput from '../../components/Form/InlineInput';
import DisclaimerBox from '../../components/Disclaimer/DisclaimerBox';
import { DisclaimerBoxStyle } from '../../shared/enum/disclaimerBoxStyles';
import EmptySpace from '../../components/EmptySpace';
import detectDicomDateFormat, {
  convertDateStringFromFormat,
} from '../../shared/utils/detectDicomDateFormat';
import { dicomTags } from '../../shared/staticText/rawDicomLabelTags';
import { priorityDicomTags, skipDicomTags } from '../../shared/utils/skipDicomInputs';
import Section from '../../components/Section/Section';
import { ReactComponent as StudyProcessingSvg } from '../../assets/studyProcessing.svg';
import config from '../../config';
import { ReleaseSessionDataInterface } from '../../shared/hooks/useExternal';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const UpdateDicomFields = ({ dicomData }: { dicomData: Record<string, string> }) => {
  const { setFieldValue, getFieldMeta } = useFormikContext();

  useEffect(() => {
    const birthday = getFieldMeta('user.birthday');
    const dicomStudyDate = getFieldMeta('study.date');

    if (
      dicomData['x00080020'] &&
      dicomData['x00100030'] &&
      birthday.value &&
      !dicomStudyDate.value &&
      !(dicomStudyDate.value instanceof Date)
    ) {
      const dicomDateFormat = detectDicomDateFormat(dicomData['x00100030'], String(birthday.value));
      if (dicomDateFormat) {
        const studyDate = convertDateStringFromFormat(dicomData['x00080020'], dicomDateFormat);
        setFieldValue('study.date', studyDate);
      }
    }
    if (dicomData['x00081030']) {
      if (!getFieldMeta('study.name').value) {
        setFieldValue('study.name', dicomData['x00081030']);
      }
    }
    Object.entries(dicomData).map(([key, value]) => {
      if (getFieldMeta(`extraData.${key}`).value) return;
      setFieldValue(`extraData.${key}`, value);
    });
  }, [dicomData, setFieldValue, getFieldMeta]);

  return null;
};

interface ReleaseRecordProps {
  isExternal?: boolean;
  releaseSessionData?: ReleaseSessionDataInterface;
  onCancelSession?: () => void;
}

const ReleaseRecordPage = ({
  isExternal,
  releaseSessionData,
  onCancelSession,
}: ReleaseRecordProps): ReactElement => {
  const [formValues] = useState<DicomAddFormInterface>({
    user: {
      name: releaseSessionData?.user?.name ?? '',
      email: '',
      ssn: '',
      birthday: releaseSessionData?.user?.birthday ?? '',
    },
    study: {
      name: '',
      date: '',
      physicianName: '',
      physicianEmail: '',
    },
  });

  const [relesedStudyId, setReleasedStudyId] = useState<number>();

  const {
    formLoading,
    uploadProgress,
    dicomData,
    setFiles,
    files,
    setPatientValidationData,
    fileErrors,
    validFilesChecks,
    showSecondStep,
    setShowSecondStep,
    onCancelRelease,
    handleReleaseForm,
    dicomFileAdded,
    uploadedStudy,
    errorMessage,
  } = useRelease({ isExternal, releaseSessionData });

  const onSubmit = async (
    values: DicomAddFormInterface,
    { resetForm }: FormikHelpers<DicomAddFormInterface>,
  ) => {
    const releasedStudyId = await handleReleaseForm(values);
    if (!releasedStudyId) return;
    resetForm();
    setReleasedStudyId(releasedStudyId);
  };

  const onContinue = () => {
    setShowSecondStep(true);
  };

  const validationSchema = Yup.object({
    user: Yup.object().shape({
      ...(!isExternal
        ? {
            name: Yup.string().required(messages.requiredField),
            email: Yup.string().email(messages.invalidEmail).required(messages.requiredField),
            ssn: Yup.string()
              .required(messages.requiredField)
              .min(5, messages.min5charsLength)
              .max(5, messages.min5charsLength),
            birthday: Yup.date()
              .required(messages.requiredField)
              .max(new Date(), messages.onlyPastDates),
          }
        : {}),
    }),
    study: Yup.object().shape({
      name: Yup.string().required(messages.requiredField),
      date: Yup.date().required(messages.requiredField),
    }),
  });

  const [showAllFields, setShowAllFields] = useState(false);

  const handleShowMore = () => {
    setShowAllFields((prev) => !prev);
  };

  const handleFieldChange = (field: string, newVal: string) => {
    setPatientValidationData((prevData) => ({
      ...prevData,
      [field]: newVal,
    }));
  };

  if (uploadedStudy) {
    return (
      <Section>
        <div className={styles.succesWrapper}>
          <StudyProcessingSvg width={396} height={82} />
          <h1>Study is being minted</h1>
          <p>
            The study uploaded will be processed and minted soon. You will receive an email when the
            process finished. You can leave this page, the process is done in the backend.
          </p>
          {relesedStudyId && <p>Study id: {relesedStudyId}</p>}
          <Button onClick={onCancelSession ?? onCancelRelease} style='navy' size='large'>
            Release another study
          </Button>
          {!isExternal && (
            <Button toPage={config.routes.releaseHistory} style='naked' size='large'>
              View release history
            </Button>
          )}
        </div>
      </Section>
    );
  }

  return (
    <SectionDouble
      leftSide={
        <div>
          {!showSecondStep && (
            <div className={styles.title}>Who would you like to send medical records to?</div>
          )}
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ resetForm, errors, touched, values }) => {
              const isFirstStepValid =
                values?.user?.name &&
                !errors?.user?.name &&
                !errors?.user?.email &&
                !errors?.user?.ssn &&
                !errors?.user?.birthday;
              const disableContinueBtn = !isFirstStepValid;

              const onCancel = () => {
                resetForm();
                onCancelRelease();
                onCancelSession && onCancelSession();
              };

              return (
                <Form className={styles.form}>
                  {!showSecondStep && (
                    <div className={cx(styles.firstStep, styles.fieldsGroup)}>
                      <InputField
                        name='user.name'
                        label={dicomLabels.patientName}
                        type='text'
                        onChange={handleFieldChange}
                        required
                      />
                      <InputField
                        name='user.email'
                        label={dicomLabels.patientEmail}
                        type='text'
                        required
                      />
                      <InputField
                        name='user.ssn'
                        label={dicomLabels.ssn}
                        type='password'
                        required
                        maxLength={5}
                        regex={/^\d*$/}
                      />
                      <InputField
                        name='user.birthday'
                        label={dicomLabels.birthday}
                        type='date'
                        onChange={handleFieldChange}
                        required
                      />
                      <div className={styles.buttons}>
                        <Button
                          type='button'
                          style='navy'
                          size='large'
                          fullWidth
                          disabled={disableContinueBtn}
                          onClick={onContinue}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  )}
                  {showSecondStep && (
                    <div
                      className={cx(
                        styles.secondStep,
                        styles.fieldsGroup,
                        formLoading && styles.loadingForm,
                      )}
                    >
                      <BackButton onClick={onCancel} alternativeText='Cancel' />
                      <div className={styles.group}>
                        <div className={cx(styles.title, styles.groupTitle)}>Patient details</div>
                        <InlineInput
                          isEditable={!isExternal}
                          name='user.name'
                          label={dicomLabels.patientName}
                          onChange={handleFieldChange}
                        />

                        {!isExternal && (
                          <InlineInput name='user.email' label={dicomLabels.patientEmail} />
                        )}
                        {!isExternal && <InlineInput name='user.ssn' label={dicomLabels.ssn} />}
                        <InlineInput
                          name='user.birthday'
                          label={dicomLabels.birthday}
                          formatDateDisplay
                        />
                      </div>
                      {!dicomData && (
                        <>
                          <DisclaimerBox
                            type={DisclaimerBoxStyle.INFO}
                            content='Add medical images in order to continue the release process. Upload at least one DICOM file.'
                          />
                          <EmptySpace height={100} />
                        </>
                      )}
                      {dicomData && (
                        <div className={styles.groupStudy}>
                          <UpdateDicomFields dicomData={dicomData} />
                          <div className={cx(styles.title, styles.groupTitle)}>Study details</div>
                          <InputField
                            name='study.name'
                            label={dicomLabels.studyName}
                            type='text'
                            required
                          />
                          <InputField
                            name='study.date'
                            label={dicomLabels.dicomStudyDate}
                            type='date'
                            required
                          />
                          <InputField
                            name='study.physicianName'
                            label={dicomLabels.physicianName}
                            type='text'
                          />
                          <InputField
                            name='study.physicianEmail'
                            label={dicomLabels.physicianEmail}
                            type='email'
                            disclaimer='Study will be automatically shared with the physician, if an email address is provided.'
                          />
                        </div>
                      )}
                      {dicomData && (
                        <div className={styles.groupStudy}>
                          <div className={cx(styles.title, styles.groupTitle)}>Extracted data</div>
                          {priorityDicomTags.map((key) => (
                            <InlineInput
                              key={key}
                              name={`extraData.${key}`}
                              label={dicomTags[key] || key}
                            />
                          ))}
                          {Object.entries(dicomData)
                            .filter(
                              ([key]) =>
                                !priorityDicomTags.includes(key) && !skipDicomTags.includes(key),
                            )
                            .slice(0, showAllFields ? undefined : 3)
                            .map(([key]) => (
                              <InlineInput
                                key={key}
                                name={`extraData.${key}`}
                                label={dicomTags[key] || key}
                              />
                            ))}
                          <div>
                            <Button onClick={handleShowMore} style='naked' type='button'>
                              {showAllFields ? 'Collapse' : 'View all'} data
                            </Button>
                          </div>
                        </div>
                      )}
                      {Object.keys(errors).length > 0 && Object.keys(touched).length > 5 && (
                        <div className={styles.formError}>Some fields have errors.</div>
                      )}
                      {Boolean(Object.keys(fileErrors).length) && (
                        <div className={styles.formError}>Some files have errors.</div>
                      )}
                      {Boolean(files.length) && !dicomFileAdded && (
                        <div className={styles.formError}>Add at least one dicom file</div>
                      )}
                      {errorMessage && <div className={styles.formError}>{errorMessage}</div>}
                      {formLoading && <ProgressBar progress={uploadProgress} />}
                      <div className={styles.buttons}>
                        <Button
                          style='naked'
                          fullWidth
                          size='large'
                          onClick={onCancel}
                          disabled={formLoading}
                          type='button'
                        >
                          Cancel
                        </Button>
                        <Button
                          type='submit'
                          style='navy'
                          size='large'
                          fullWidth
                          disabled={!validFilesChecks || !dicomFileAdded || formLoading}
                          loading={formLoading}
                        >
                          Release
                        </Button>
                      </div>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      }
      rightSide={
        <>
          {!showSecondStep && <ReleaseInstructions />}
          {showSecondStep && (
            <FileDrop
              loading={formLoading}
              files={files}
              setFiles={setFiles}
              fileErrors={fileErrors}
            />
          )}
        </>
      }
    />
  );
};

export default ReleaseRecordPage;
