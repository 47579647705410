import React, { ReactElement } from 'react';
import cx from 'classnames';
import styles from './Tag.module.css';
import { ActivityType } from '../../shared/enum/activity';

interface TagProps {
  type: 'new' | ActivityType;
  small?: boolean;
  loadingGhost?: boolean;
}

const Tag = ({ type, small, loadingGhost }: TagProps): ReactElement => {
  return (
    <div
      className={cx(
        styles.root,
        type === 'new' && styles.new,
        type === ActivityType.RELEASE && styles.release,
        (type === ActivityType.SHARE || type === ActivityType.SHARED) && styles.share,
        (type === ActivityType.REVOKE || type === ActivityType.REVOKED) && styles.revoke,
        small && styles.small,
        loadingGhost && styles.loadingGhost,
      )}
    >
      {type === 'new' && 'New'}
      {type === ActivityType.RELEASE && 'Release'}
      {(type === ActivityType.SHARE || type === ActivityType.SHARED) && 'Share'}
      {(type === ActivityType.REVOKE || type === ActivityType.REVOKED) && 'Revoke access'}
      {loadingGhost && '- - - - - - - - - - -'}
    </div>
  );
};

export default Tag;
