const messages = {
  errorOccured: 'An error occurred',
  invalidShare: 'Invalid share',
  alreadySharedToUser: 'Already shared to this user',
  revokedSucces: 'Revoked successfully requested',
  sharedSuccess: 'Shared successfully requested',
  userNotFound: 'Account does not exist',
  requiredField: 'This field is mandatory',
  invalidEmail: 'Invalid email',
  invalidFiletype: 'Invalid file type. Only .dcm, .pdf, .doc, .zip, .hl7, .fhir files are allowed.',
  maxFileSize: 'File size exceeds the limit of 1000MB.',
  maxTotalFilesSize: 'Total files size limit is 5GB',
  dicomDownloadError: 'Dicom download error',
  dicomImageDicoderInitializeFailed: 'Failed to initialize Dicom viewer',
  onlyPastDates: 'Please add a date from the past',
  minLenght6: 'The length of allowed codes is 6',
  invalidCodeOrBirthday: 'Invalid access code or birthday',
  expiredAccessCode: 'Expired access code',
  atLeastOneDicom: 'Upload at least one Dicom file',
  dobNotMatchError: 'Patient date of birth do not match with the date stored in the app',
  min5charsLength: 'SSN must be 5 numbers long',
};

export default messages;
