import axios from 'axios';
import { AccesCodeLoginType, LoginType } from '../models/authType';

import config from '../../config';
import { formatDateInheritLocale } from '../utils/formatDateInheritLocale';

export const login = ({ email, password }: LoginType) => {
  return axios.post(`${config.api.baseUrl}${config.api.loginPath}`, {
    email,
    password,
  });
};

export const authAccess = ({ code, birthday }: AccesCodeLoginType) => {
  return axios.post(`${config.api.baseUrl}${config.api.accessLoginPath}`, {
    code,
    birthday: formatDateInheritLocale(birthday),
  });
};
