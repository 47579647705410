const calculateSimilarity = (str1: string, str2: string): number => {
  let matches = 0;
  const length = Math.max(str1.length, str2.length);

  for (let i = 0; i < length; i++) {
    if (i < str1.length && i < str2.length && str1[i] === str2[i]) {
      matches++;
    }
  }

  return (matches / length) * 100;
};

const areStringsSimilar = (
  str1: string,
  str2: string,
  similarityThreshold: number = 80,
): boolean => {
  return calculateSimilarity(str1.toLowerCase(), str2.toLowerCase()) >= similarityThreshold;
};

export default areStringsSimilar;
