import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as dicomParser from 'dicom-parser';
import { DicomData, DicomPatch } from '../models/dicom';
import {
  getStudyByid,
  getSharedWithMeStudies,
  getUserStudies,
  getStudyFilesByid,
  getStudySharesByid,
  getAllStudies,
  getAllStudiesRequests,
} from '../services/dicom.service';
import { StudyHistoryTable, StudyInterface, StudyRequestHistoryTable } from '../models/study';
import { StudyFileInterface } from '../models/file';
import { PaginatedResponse } from '../models/paginatedResponse';
import formatDate from '../utils/formatDate';
import { StudyShareInterface } from '../models/shares';

interface UserStudyHook {
  fetchLoading: boolean;
  autoRefreshLoading: boolean;
  dicomData: DicomPatch | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  file: File | null;
  fetchUserStudies: ({
    sharedWithMe,
    page,
    filters,
  }: {
    sharedWithMe?: boolean;
    page: number;
    filters?: Record<string, string>;
  }) => void;
  fetchStudiesHistory: ({
    page,
    filters,
    isAutoRefresh,
  }: {
    page: number;
    filters?: Record<string, string>;
    isAutoRefresh?: boolean;
  }) => void;
  userStudies: PaginatedResponse<StudyInterface> | undefined;
  studiesHistory: PaginatedResponse<StudyHistoryTable> | undefined;
  studyFiles: PaginatedResponse<StudyFileInterface> | undefined;
  fetchStudyById: (id: string) => Promise<StudyInterface | undefined>;
  fetchStudyFiles: (studyId: number, page: number) => Promise<void>;
  fetchStudyShares: (studyId: number, page: number, isAutoRefresh?: boolean) => Promise<void>;
  studyShares: PaginatedResponse<StudyShareInterface> | undefined;
  studiesRequestsHistory: PaginatedResponse<StudyRequestHistoryTable>;
  fetchStudiesRequestHistory: ({
    page,
    filters,
    isAutoRefresh,
  }: {
    page: number;
    filters?: Record<string, string>;
    isAutoRefresh?: boolean;
  }) => void;
  page: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

interface DicomDataSet {
  string: (tag: string) => string | undefined;
}

const useStudy = (): UserStudyHook => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [autoRefreshLoading, setAutoRefreshLoading] = useState<boolean>(false);
  const [dicomData, setDicomData] = useState<DicomData>();
  const [file, setFile] = useState<File | null>(null);
  const [userStudies, setUserStudies] = useState<PaginatedResponse<StudyInterface>>();
  const [studiesHistory, setStudiesHistory] = useState<PaginatedResponse<StudyHistoryTable>>();
  const [studiesRequestsHistory, setStudiesRequestsHistory] =
    useState<PaginatedResponse<StudyRequestHistoryTable>>();
  const [studyFiles, setStudyFiles] = useState<PaginatedResponse<StudyFileInterface>>();
  const [studyShares, setStudyShares] = useState<PaginatedResponse<StudyShareInterface>>();
  const [page, setPage] = useState<number>(0);

  const fetchUserStudies = useCallback(
    async ({
      sharedWithMe,
      page,
      filters,
    }: {
      sharedWithMe?: boolean;
      page: number;
      filters?: Record<string, string>;
    }) => {
      try {
        setFetchLoading(true);
        const dicoms = sharedWithMe
          ? await getSharedWithMeStudies()
          : await getUserStudies(page, filters);
        setUserStudies(dicoms);
      } catch (error) {
        toast.error('An error occurred during fetch.');
        console.log(error);
      } finally {
        setFetchLoading(false);
      }
    },
    [],
  );

  const fetchStudyById = useCallback(async (id: string): Promise<StudyInterface | undefined> => {
    try {
      setFetchLoading(true);
      return await getStudyByid(id);
    } catch (error) {
      console.log(error);
      return undefined;
    } finally {
      setFetchLoading(false);
    }
  }, []);

  const fetchStudiesHistory = useCallback(
    async ({
      page,
      filters,
      isAutoRefresh,
    }: {
      page: number;
      filters?: Record<string, string>;
      isAutoRefresh?: boolean;
    }): Promise<void> => {
      try {
        !isAutoRefresh && setFetchLoading(true);
        isAutoRefresh && setAutoRefreshLoading(true);
        const studies = await getAllStudies(page, filters);
        const preparedStudies = {
          ...studies,
          data: studies.data?.map((study) => ({
            id: study.id,
            studyDate: study.data?.studyDetails?.date && formatDate(study.data?.studyDetails.date),
            studyName: study.data?.studyDetails?.name,
            patient: study.user?.data?.name,
            birthday: study.user?.data?.birthday && formatDate(study.user?.data?.birthday),
            physician: study.data?.studyDetails?.physicianName,
            uploadedDate: study.createdAt,
            status: study.status,
            action: study.id,
            externalProviderName: study.access?.externalProvider?.name,
            externalRequestDate: study.access?.createdAt && formatDate(study.access?.createdAt),
          })),
        };

        setStudiesHistory(preparedStudies);
      } catch (error) {
        console.log(error);
        !isAutoRefresh && setFetchLoading(false);
        isAutoRefresh && setAutoRefreshLoading(false);
      } finally {
        !isAutoRefresh && setFetchLoading(false);
        isAutoRefresh && setAutoRefreshLoading(false);
      }
    },
    [],
  );

  const fetchStudiesRequestHistory = useCallback(
    async ({
      page,
      filters,
      isAutoRefresh,
    }: {
      page: number;
      filters?: Record<string, string>;
      isAutoRefresh?: boolean;
    }): Promise<void> => {
      try {
        !isAutoRefresh && setFetchLoading(true);
        isAutoRefresh && setAutoRefreshLoading(true);
        const studies = await getAllStudiesRequests(page, filters);
        const preparedStudies = {
          ...studies,
          data: studies.data.map((study) => ({
            patientId: study.access.patientId,
            patientName: study.access.patientName,
            patientEmail: study.access.patientEmail,
            patientBirthday:
              study.access.patientBirthday && formatDate(study.access.patientBirthday),
            providerName: study.access.fromName,
            npi: study.access.npi,
            createdAt: study.access.createdAt,
            accessCodeExpire: study.access.accessCodeExpire,
            uploadedStudies: study.studiyCounterAssociated,
            id: study.access.id,
          })),
        };

        setStudiesRequestsHistory(preparedStudies);
      } catch (error) {
        console.log(error);
        !isAutoRefresh && setFetchLoading(false);
        isAutoRefresh && setAutoRefreshLoading(false);
      } finally {
        !isAutoRefresh && setFetchLoading(false);
        isAutoRefresh && setAutoRefreshLoading(false);
      }
    },
    [],
  );

  const extractDICOMData = (dataSet: DicomDataSet) => {
    const getDicomElement = (tag: string) => dataSet.string(tag) || undefined;

    const newData = {
      patientName: getDicomElement('x00100010') || '',
      patientEmail: '',
      patientDOB: getDicomElement('x00100030') || '',
      providerNpi: getDicomElement('x00101050') || '',
      providerName: '',
      referringPhysicianName: getDicomElement('x00080090') || '',
      performingPhysicianName: getDicomElement('x00081050') || '',
      physicianEmail: '',
      radiologistName: '',
      radiologistEmail: '',
      fileSetId: getDicomElement('x00041130') || '',
      imageId: getDicomElement('x00080018') || '',
      dicomStudyId: getDicomElement('x00200010') || '',
      dicomStudyDate: getDicomElement('x00080020') || '',
      dicomStudyDescription: getDicomElement('x00081030') || '',
      dicomAccessionId: getDicomElement('x00080050') || '',
      dicomSeriesId: getDicomElement('x0020000e') || '',
      dicomSeriesModality: getDicomElement('x00080060') || '',
      dicomSeriesDescription: getDicomElement('x0008103e') || '',
      dicomSeriesNumber: getDicomElement('x00200011') || '',
      dicomBodyPartExamined: getDicomElement('x00180015') || '',
      dicomSOPInstanceId: getDicomElement('x00080018') || '',
      dicomPatientName: getDicomElement('x00100010') || '',
      dicomDOB: getDicomElement('x21000040') || '',
    };

    setDicomData(newData);
  };

  const processDICOMFile = useCallback((byteArray: Uint8Array) => {
    try {
      const dataSet = dicomParser.parseDicom(byteArray);
      extractDICOMData(dataSet);
    } catch (error) {
      console.error('Eroare la parsarea fișierului DICOM', error);
    }
  }, []);

  useEffect(() => {
    setDicomData(undefined);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target && e.target.result) {
          processDICOMFile(new Uint8Array(e.target.result as ArrayBuffer));
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }, [file, processDICOMFile]);

  const fetchStudyFiles = useCallback(async (studyId: number, page = 0) => {
    try {
      setFetchLoading(true);
      const response = await getStudyFilesByid(String(studyId), page);
      setStudyFiles(response);
    } catch (error) {
      toast.error('Error fetching study files');
      console.error(error);
    } finally {
      setFetchLoading(false);
    }
  }, []);

  const fetchStudyShares = useCallback(
    async (studyId: number, page = 0, isAutoRefresh?: boolean) => {
      try {
        !isAutoRefresh && setFetchLoading(true);
        const response = await getStudySharesByid(String(studyId), page);
        setStudyShares(response);
      } catch (error) {
        toast.error('Error fetching study shares');
        console.error(error);
      } finally {
        !isAutoRefresh && setFetchLoading(false);
      }
    },
    [],
  );

  const onPageChange = useCallback(
    ({ selected }) => {
      setPage(selected);
    },
    [setPage],
  );

  return {
    fetchLoading,
    autoRefreshLoading,
    dicomData,
    setFile,
    file,
    fetchUserStudies,
    fetchStudiesHistory,
    userStudies,
    studiesHistory,
    studiesRequestsHistory,
    fetchStudiesRequestHistory,
    fetchStudyById,
    studyFiles,
    fetchStudyShares,
    studyShares,
    fetchStudyFiles,
    page,
    onPageChange,
  };
};

export default useStudy;
