import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { Cake, InfoSquare, Upc } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import styles from './requestHistory.module.css';
import Filters from '../../components/Filters/Filters';
import useStudy from '../../shared/hooks/useStudy';
import Pagination from '../../components/Pagination/Pagination';
import config from '../../config';
import { FilterInterface } from '../../shared/models/filter';
import { FieldTypes } from '../../shared/enum/fieldTypes';
import StudyDate from '../../components/Table/StudyDate';
import StudyAccess from '../../components/Table/StudyAccess';
import { TableColumnInterface } from '../../shared/models/table';
import ExternalFlag from '../../components/ExternalFlag/ExternalFlag';
import Section from '../../components/Section/Section';
import HistoryTabsButtons from '../../components/HistoryTabsButtons/HistoryTabsButtons';
import Table from '../../components/Table/Table';
import ActiveRequestCell from '../../components/Table/ActiveRequestCell';
import { checkIfDateIsPast } from '../../shared/utils/checkDateIfIsPast';

const RequestHistoryPage = (): ReactElement => {
  const { page, onPageChange, fetchLoading, fetchStudiesRequestHistory, studiesRequestsHistory } =
    useStudy();

  const [filterParams, setFilterParams] = useState<Record<string, string>>({});
  const [, setRefreshTimer] = useState(config.customization.autoRefreshTimerInSeconds);

  const handleFilterFieldChange = useCallback(
    (field: string, newVal?: string) => {
      setFilterParams((prevData) => {
        const newData = { ...prevData };
        if (!newVal) {
          delete newData[field];
        } else {
          newData[field] = newVal;
        }
        return newData;
      });
      onPageChange({ selected: 0 });
    },
    [onPageChange],
  );

  useEffect(() => {
    fetchStudiesRequestHistory({ page, filters: filterParams });
  }, [fetchStudiesRequestHistory, page, filterParams]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshTimer((prevTimer) => {
        if (prevTimer === 1) {
          fetchStudiesRequestHistory({ page, filters: filterParams, isAutoRefresh: true });
          return config.customization.autoRefreshTimerInSeconds;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [fetchStudiesRequestHistory, page, filterParams]);

  const filters: FilterInterface[] = [
    {
      name: 'createdAt',
      type: FieldTypes.DATE,
      label: 'Requested on',
    },
  ];

  const columns: TableColumnInterface[] = [
    {
      header: 'Provider',
      key: 'providerNameAndNPI',
      minWidth: '200px',
    },
    {
      header: 'Patient',
      key: 'patientNameAndDate',
      minWidth: '200px',
    },
    {
      header: 'Requested on',
      key: 'createdAt',
    },
    {
      header: 'Uploaded studies',
      key: 'uploadedStudies',
      width: '160px',
    },
    {
      header: 'Status',
      key: 'status',
      width: '110px',
    },
    {
      header: '',
      key: 'action',
      width: '160px',
    },
  ];

  const tableData = React.useMemo(
    () => (fetchLoading ? Array(8).fill({}) : studiesRequestsHistory?.data),
    [fetchLoading, studiesRequestsHistory?.data],
  );

  const tableCellRenderer = (
    row: Record<string, string>,
    column: TableColumnInterface,
  ): ReactElement | string => {
    switch (column.key) {
      case 'createdAt':
        return <StudyDate cell={row[column.key]} />;

      case 'status':
        return <ActiveRequestCell status={checkIfDateIsPast(row.accessCodeExpire)} />;

      case 'action':
        return (
          <StudyAccess
            buttons={[
              {
                label: 'View studies',
                url: config.routes.requestHistoryFromId.replace(':id', row.id as string),
              },
            ]}
          />
        );

      case 'patientNameAndDate':
        return (
          <>
            <div className={styles.studyName}>
              <Link
                className={styles.link}
                to={config.routes.patientRecords.replace(':id', String(row.patientId))}
              >
                <span>{row.patientName}</span>
              </Link>
            </div>
            <small>
              <Cake className={styles.dateSvg} data-tooltip-id='patientBirthday' />{' '}
              {row.patientBirthday}
            </small>
          </>
        );

      case 'providerNameAndNPI':
        return (
          <>
            <div className={styles.studyName}>
              <span>{row.providerName}</span>
              <span>
                {row.externalProviderName && (
                  <ExternalFlag
                    providerName={row.externalProviderName}
                    requestedDate={row.externalRequestDate}
                  />
                )}
              </span>
            </div>
            {row.npi && (
              <small>
                <Upc className={styles.physicianSvg} data-tooltip-id='npiSerial' /> {row.npi}
              </small>
            )}
          </>
        );

      default:
        return row[column.key];
    }
  };

  const tableHeaderRenderer = (column: TableColumnInterface): ReactElement => {
    return (
      <>
        <span>{column.header}</span>
        <div>
          {column.key === 'status' && (
            <>
              <div data-tooltip-id='tooltipStatusInfo'>
                <InfoSquare />
              </div>
              <Tooltip id='tooltipStatusInfo'>
                <div className={styles.statusInfoTooltipInner}>
                  <h3>Legend:</h3>
                  <ActiveRequestCell status={true} />
                  <div>The access code is active and ready to use.</div>
                  <ActiveRequestCell status={false} />
                  <div>
                    The access code has expired. You can make another request to generate a new one.
                  </div>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <Section flexSpacing>
      <Filters
        lateralContent={
          <>
            <HistoryTabsButtons />
            {/* <AutoRefreshTimerDisplay refreshingNow={autoRefreshLoading} timeLeft={refreshTimer} /> */}
          </>
        }
        setFiltersValue={handleFilterFieldChange}
        filters={filters}
      />
      <Table
        tableHeaderRenderer={tableHeaderRenderer}
        tableCellRenderer={tableCellRenderer}
        tableData={tableData}
        columns={columns}
        fetchLoading={fetchLoading}
      />
      {studiesRequestsHistory?.meta &&
        studiesRequestsHistory?.meta.totalItems / config.customization.historyStudiesPerPage >
          1 && (
          <Pagination
            meta={studiesRequestsHistory.meta}
            page={page}
            onPageChange={onPageChange}
            perPage={config.customization.historyStudiesPerPage}
          />
        )}
      <Tooltip
        id='tooltipInfoEncriptedData'
        content='The data is Encripted. It will be shown here after mint process.'
      />
      <Tooltip id='patientBirthday' content='Patient date of birth' />
      <Tooltip id='npiSerial' content='NPI' />
    </Section>
  );
};

export default RequestHistoryPage;
