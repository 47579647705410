import React, { ReactElement, useState } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import cx from 'classnames';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import styles from './InputField.module.css';
import DatePickerField from './DatePicker';
import DisclaimerBox from '../Disclaimer/DisclaimerBox';
import { DisclaimerBoxStyle } from '../../shared/enum/disclaimerBoxStyles';
import CodeInputField from './CodeInputField';
import { SelectInterface } from '../../shared/models/filter';

type InputFieldProps = {
  placeholder?: string;
  name: string;
  label?: string;
  type?: string;
  required?: boolean;
  options?: SelectInterface[];
  disclaimer?: string;
  onChange?: (field: string, newVal: string) => void;
  maxLength?: number;
  regex?: RegExp;
  isCodeField?: boolean;
  classNames?: string;
  icon?: ReactElement;
  readOnly?: boolean;
};

const InputField = ({
  placeholder,
  name,
  label,
  type,
  required,
  options,
  disclaimer,
  onChange,
  maxLength,
  regex,
  isCodeField,
  classNames,
  icon,
  readOnly,
}: InputFieldProps) => {
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const [showText, setShowText] = useState(false);

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if ((type === 'text' || type === 'password') && maxLength && value.length > maxLength) {
      value = value.slice(0, maxLength);
    }
    if ((type === 'text' || type === 'password') && regex && !regex.test(value)) {
      value = getFieldMeta(name).value as string;
    }

    setFieldValue(name, value);
    if (onChange) {
      onChange(name, value);
    }
  };

  const toggleShowText = () => {
    setShowText(!showText);
  };

  const renderInput = () => {
    if (type === 'select' && options) {
      return (
        <Field
          as='select'
          name={name}
          defaultValue={''}
          className={cx(styles.input, classNames)}
          onChange={onFieldChange}
          disabled={readOnly}
        >
          <option value='' disabled>
            Choose one
          </option>
          {options.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </Field>
      );
    } else if (type === 'date') {
      return <DatePickerField name={name} onChange={onChange} />;
    } else if (isCodeField) {
      return <CodeInputField name={name} length={maxLength} />;
    } else {
      return (
        <div className={cx(styles.inputWrapper, icon && styles.withIcon)}>
          {icon && <div className={styles.icon}>{icon}</div>}
          <Field
            type={showText ? 'text' : type}
            name={name}
            placeholder={placeholder}
            className={cx(styles.input, classNames)}
            onChange={onFieldChange}
            value={getFieldMeta(name).value}
            maxLength={maxLength}
            readOnly={readOnly}
          />
          {type === 'password' && (
            <button onClick={toggleShowText} type='button' className={styles.toggleShowText}>
              {showText ? <EyeSlash /> : <Eye />}
            </button>
          )}
        </div>
      );
    }
  };

  return (
    <div className={cx(styles.root)}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {required && <sup>*</sup>}
        </label>
      )}
      {renderInput()}
      <ErrorMessage name={name} render={(msg) => <div className={styles.error}>{msg}</div>} />
      {disclaimer && <DisclaimerBox type={DisclaimerBoxStyle.INFO} content={disclaimer} />}
    </div>
  );
};

export default InputField;
