import React, { useRef, useEffect } from 'react';
import { useFormikContext } from 'formik';
import cx from 'classnames';
import styles from './InputField.module.css';

interface CodeInputFieldProps {
  name: string;
  length: number;
}

const CodeInputField = ({ name, length }: CodeInputFieldProps) => {
  const { setFieldValue, values } = useFormikContext();
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const fieldValue: string[] = values[name] || Array(length).fill('');

  useEffect(() => {
    setFieldValue(name, '');
  }, [setFieldValue, name]);

  const handleChange = (value: string, index: number) => {
    const newCode = [...fieldValue];
    newCode[index] = value;
    setFieldValue(name, newCode.join(''));

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKey = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && index > 0 && !fieldValue[index]) {
      const newCode = [...fieldValue];
      newCode[index - 1] = '';
      setFieldValue(name, newCode.join(''));
      inputRefs.current[index - 1]?.focus();
    } else if (event.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (event.key === 'ArrowRight' && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  return (
    <div className={cx(styles.input, styles.codeInputWrapper)}>
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          className={styles.codeInput}
          maxLength={1}
          value={fieldValue[index] || ''}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKey(e, index)}
          style={{ width: `calc(100% / ${length})` }}
          placeholder='-'
        />
      ))}
    </div>
  );
};

export default CodeInputField;
